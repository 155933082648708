#temp-page{
    background-image: url('../../../Assets/WebsiteLanding.jpg');
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem;
    overflow: scroll;
    scroll-behavior: smooth;
}

#landing-logo{
    width: 200px;
    max-width: 50vw;
}

#temp-footer{
    width: 100vw;
    color: white;
    background-color: unset;
    
}

#temp-login{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.landing-txt{
    font-weight: 500;
    line-height: 30px;
    font-size: 18px;
    margin: 2rem auto;
    color: #1e1e1e;
    padding: 2rem;
}

#temp-form{
    display: flex;
    flex-direction: column;
    width: 500px;
    max-width: 90vw;
    margin: 0 auto

}

#temp-form > input{
    height: 40px;
    margin: 5px 0;
    border-radius: 3px;
    border: 2px solid #a3837f;
    padding: 5px;
}

#temp-form > input::placeholder{
    color: #a3837f;
    font-weight: bold;

}

#temp-form > button{
    height: 40px;
    width: 150px;
    margin: 5px auto;
    border-radius: 3px;
    border: 2px solid #a3837f;
    padding: 5px;
    color: #a3837f;
    font-weight: bold;
    transition: 0.5s;
}

#temp-form > button:hover{
    cursor: pointer;
    background-color: #a3837f;
    color: white;
}

  
@media only screen and (max-width: 550px) {
    
    #temp-footer{
       display: flex;
       flex-wrap: wrap;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       height: auto;
    }
   
  }
  