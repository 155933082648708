#act-page{
    display: flex;
    justify-content: baseline;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    min-height: 75vh;
}

@media only screen and (max-width: 750px) {
   
    #act-page{
        padding-top: 90px;
     }
   
}