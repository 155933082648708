.indented {
    padding-left: 20px;
    margin: 15px 0;
    list-style-type: none;
}

.double-indented {
    padding-left: 40px;
    margin: 15px 0;
    list-style-type: none;
}

.terms{
    padding: 2rem; 
    width: 1200px; 
    max-width: 90vw; 
    margin: 0 auto;
    color: #313131;
    min-height: 80vh;
}