#quote-btn{
    background-color: #ecbdba;
    border: unset;
    border-radius: 3px;
    color: white;
    font-weight: bold;
}
#quote-btn:hover{
    cursor: pointer;
}

#client-request-list{
    display: flex;
    height: 100%;
    width: 100%;
    padding-top: 30px;
}

.client-request-container{
    height: 80vh;
    min-height: 600px;
    width: 420px;
    background-color: #fffafa;
    border-radius: 20px;
    padding: 20px;
    color: #313131;
    margin-right: 40px;
    overflow: scroll;
    flex-shrink: 0;

}

.request-info-header{
    letter-spacing: 2px;
    font-size: 13px;
    margin: 5px 0
}


@media only screen and (max-width: 750px) {
    #client-request-list {
      flex-direction: column;
    }
    .client-request-container{
        height: auto;
        min-height: unset;
        width: calc(100vw - 2rem);
        background-color: #fffafa;
        border-radius: 20px;
        padding: 20px;
        color: #313131;
        margin-right: 0px;
        margin-bottom: 40px;
        overflow: scroll;
        flex-shrink: 0;
    
    }
  }