#invoice{
    width: 70vw;
    max-width: 1000px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    color: #313131;
    margin: 0 auto;
}

#invoice h4{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
}

#invoice h5{
    margin: 10px 0;
}

.invoice-logo{
    width: 200px;
}

#address-to-container{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 1rem 0;
}

#address-from-container{
    display: flex;
    flex-direction: column;
    text-align: right;
    margin: 1rem 0;
}

#address-to-container p, #address-from-container p{
    margin: 5px 0;
}

#invoice-details{
    text-align: right;
}

#invoice-details h1{
    font-family: 'Mont', sans-serif;
}

#invoice-table {
    border-collapse: collapse;
    margin: 50px 0;

}

#invoice-table > thead > tr {
    border-bottom: 2px solid #313131;
    border-top: 2px solid #313131;
}

#invoice-table > tbody > tr, .table-underline {
    border-bottom: 1px solid #313131;
}

#invoice-table th, #invoice-table td{
    padding: 1rem 0;
    text-align: center;
}

#invoice-table tr td:first-child, #invoice-table th:first-child {
    width: 50%;
    text-align: left;

}
