#inbox-page{
    background-color: #f5f5f5;
    min-height: 90%;
    padding: 2rem;
}

#inbox-container{
    width: 90vw;
    max-width: 1300px;
    margin: 0 auto;
}

.inbox-row{
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ECEFF4;

}
.inbox-row:hover{
    cursor: pointer;
}

.inbox-name{
    font-weight: bold;
    font-size:1.1rem;
    color:#3B4252
}
