.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(17,17,17,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.modal-container{
    position: relative;
    width: 90vw;
    max-width: 550px;
    max-height: 90vh;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #313131;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;

}

.share-container{
    width: calc(50% - 5px);
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    text-decoration: none;
    color: #313131;
    margin: 2.5px 5px 2.5px 0;
}

.single-input{
    height: 54px;
    border: 1px solid grey;
    padding-left: 10px;
    font-size: 16px;
    border-radius: 8px;
    flex-shrink: 0;
    background-color: unset;
    -webkit-appearance: none;

}

.left-input,
.right-input{
    height: 54px;
    width: 50%;
    border: 1px solid grey;
    padding: 10px;
    font-size: 16px;
    background-color: unset;
}

.left-input{
    border-radius: 8px 0 0 8px;
    border-right: 0;
}

.right-input{
    border-radius: 0 8px 8px 0;
}

.top-input, .bottom-input, .middle-input{
    height: 54px;
    border: 1px solid grey;
    padding: 10px;
    font-size: 16px;
    background-color: unset;
    flex-shrink: 0;
}

.top-input{
    border-bottom: 0;
    border-radius: 8px 8px 0 0 ;
}

.bottom-input{
    border-radius: 0 0 8px 8px ;
    -webkit-appearance: none;
}

.middle-input{
    border-bottom: 0;
    border-radius: 0 ;
    background-color: unset;
    border-radius: 0;
    -webkit-appearance: none;
}

.modal-btn{
    background-color: #e3a8a4;
    border: unset;
    border-radius: 8px;
    height: 48px;
    color: white;
    font-size: 16px;
    margin: 1rem 0 ;
    font-family: 'Mont';
    flex-shrink: 0;
}

.close-modal-btn{
    height: 20px;
    width: 20px;
    position: absolute;
    top: calc(2rem + 3px);
}

.modal-header{
    text-align: center;
    margin-top: 0;
    font-family: 'Mont';
}

.modal-event-container{
    padding: 10px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

#pay-btns{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.modal-description{
    max-width: 100%;
}

#forgot-btn{
    background-color: unset;
    border: unset;
    font-weight: bold;
    color: #313131;
}

.modal-ticket-container {
    padding: 10px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.modal-description {
    max-width: 100%;
}

.modal-txt {
    font: 14;
    margin: 8px 0;
    margin-left: 0px;
    margin-bottom: 10px
}

.modal-txt p {
    margin: 0;
    margin-bottom: 3px;
}

.modal-label {
    display: inline-block;
    width: 140px;
    text-align: left;
    margin-right: 10px;
    font-weight: bold;
}

.modal-title {
    font-weight: bold;
    min-width: 300px;
}

.modal-textarea {
    width: 100%;
    height: 80px;
    margin: 0;
    padding: 5px;
    resize: none;
    font-family: inherit;
}


.divider{
    width: 100%;
    height: 1px;
    background-color: #dfdfdf;
    margin: 5px 0;
}

.notes-container {
    max-height: 150px;
    overflow-y: auto;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.note {
    padding: 5px;
    border-bottom: 1px solid #ddd;
}

.note:last-child {
    border-bottom: none;
}

.no-notes {
    color: #999;
    font-style: italic;
}

@media only screen and (max-width: 748px) {
    .modal-event-container{
        padding: 10px 0;
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 550px) {
    .share-container{
        width: 100%;
        margin: 2.5px 0;
    }
}