#faqs-container{
    width: 95vw;
    max-width: 1500px;
    margin: 2rem auto;
    background-color: rgba(252,224,224,0.32);
    padding: 4rem;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}

#faqs-container h2{
    font-weight: 900;
    font-size: 28px;
    color: #313131;
}

#bg-hearts{
    height: 90%;
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 50%;
    object-fit: contain;
    z-index: 1;

}

.question{
    padding: 20px;
    border-bottom: 1px solid #EABAB7;
    position: relative;
    z-index: 99;
}

.faq-container{
    width: 100%;
    height: 100px;
    margin: 10px 0;
    padding: 35px 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.5s ease-in-out; 
    border-bottom: 1px solid #EABAB7;
    position: relative;
    z-index: 99;
}

.faq-container-open{
    width: 100%;
    height: 200px;
    margin: 10px 0;
    border-bottom: 1px solid #EABAB7;
    padding: 35px 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.5s ease-in-out;
    position: relative;
    z-index: 99
}

.faq-container:hover, .faq-container-open:hover{
    cursor: pointer;
}

.faq-container h6, .faq-container-open h6{
    margin: 0;
    width: 90%;
    color: #EABAB7;
    font-size: 22px;
    font-weight: 900;

}

.open-close{
    width: 20px;
    height: 20px;
    transition: 0.5s ease-in-out;
    margin-left: 5px;

}

.close-close{
    width: 20px;
    height: 20px;
    transition: 0.5s ease-in-out;
    transform: rotate(-45deg);
    margin-left: 5px;

}

.open-answer{
    visibility: visible;
    opacity: 1;
    transition: opacity 0.7s ease-in-out;
    color: #A28F89;
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 45px;
    font-size: 18px;
}

.close-answer{
    opacity: 0;
    transition: 0.7s ease-in-out;
    color: #A28F89;
    margin-top: 20px;
    visibility: hidden;
    padding-right: 10px;
    padding-left: 45px;
    font-size: 18px;

}

.icon {
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 20px;
  }
  
  .icon::before, .icon::after {
    content: '';
    position: absolute;
    background-color: #000;
    transition: transform 0.3s ease;
  }
  
  .icon::before {
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    transform: translateY(-50%);
    background-color: #EABAB7;

  }
  
  .icon::after {
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    transform: translateX(-50%);
    background-color: #EABAB7;
  }
  
  .icon.open::after {
    transform: translateX(-50%) scaleY(0);
  }


  @media only screen and (max-width: 1000px) {

    #faqs-container{
        padding: 2rem;
    }

    .faq-container{
        padding: 10px;
    }

    .faq-container-open{
        padding: 10px;
        height: 320px;
    }

    .open-answer, .close-answer{
    
        padding-left: 0px;
        padding-right: 0px;
        font-size: 16px;
    }

    .faq-container h6, .faq-container-open h6{
        font-size: 20px;    
    }
}