.client-how-it-works-hero{
    display: flex;
    width: 1400px;
    max-width: 100vw;
    margin: 0 auto;
    padding: 4rem 0;
    align-items: center;
    justify-content: space-evenly;
}

.client-how-it-works-img{
    width: 600px;
    max-width: 50%;
}

.hero-info{
    width: 500px;
    padding: 0 20px;
    max-width: 100vw;
}

.hero-info h1{
    font-weight: 900;
    font-size: 44px;
}

.hero-info p{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 40px;
}

.client-how-it-works-step{
    background-color: #fffafa;
    width: 100vw;
}
.client-how-it-works-step-container{
    display: flex;
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    padding: 4rem 2rem;
    align-items: center;
    justify-content: space-evenly;
}

.client-how-it-works-step-info{
    padding: 1rem;
    max-width: 600px;
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (max-width: 748px) {
    .client-how-it-works-hero{
        flex-direction: column;
        padding: 1rem 0;
    }
    .client-how-it-works-step-container{
        flex-direction: column;
        padding: 1.5rem;
    }
    .client-how-it-works-step-info{
        padding: 0;
        max-width: 100%;
      
    }

    .client-how-it-works-step{
        padding: 0;
    }

    .client-how-it-works-img{
        max-width: 100%;
        margin: 1rem 0;
    }
    #need-more-music{
        margin-left: 20%;
        width: 80% !important;
    }

    #bell-container{
        align-items: unset;
    }
}