#thumbnail{
    padding: 20px;
    text-align: center;
}

.thumb-media{
    width: 20vw;
    height: calc(20vw - 3rem);
    object-position: center;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;
}

.thumb-media:hover{
    cursor: pointer;
}

.view-profile-btn{
    border: 2px solid #313131;
    text-decoration: none;
    color: #313131;
    font-weight: 900;
    width: 15vw;
    height: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    transition: 0.3s ease-in-out;

}


  @media only screen and (max-width: 1024px) {
    .thumb-media{
        width: 30vw;
        height: calc(30vw - 3rem);
    }
    .view-profile-btn{
        width: 25vw;
    }
  
  }

  @media only screen and (max-width: 800px) {
    .thumb-media{
        width: 40vw;
    height: calc(40vw - 3rem);
    }
    .view-profile-btn{
        width: 40vw;
    }
  }

  @media only screen and (max-width: 550px) {
    .thumb-media{
        width: 70vw;
        height: calc(70vw - 3rem);
    }
    .view-profile-btn{
        width: 70vw;
    }
    
}

  


