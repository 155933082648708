.blog-header{
    width: 100vw;
    height: 60vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-header-img{
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.blog-header-title{
    position: relative;
    color: white;
    font-family: 'Mont';
    max-width: 70vw;
    text-align: center;
}

.blog-subheader{
    position: relative;
    color: #1e1e1e;
    font-family: 'Mont';
    width: 1200px;
    max-width: 90vw;
    margin: 1rem auto;
    font-size: 20px;
}

#white-overlay{
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
}

.blog-intro{
    width: 1200px;
    max-width: 90vw;
    margin: 1rem auto;
    font-size: 18px;
    letter-spacing: 1px;
    color: #1e1e1e;
    line-height: 25px;
}

.json-html{
    width: 1200px;
    max-width: 100vw;
    margin: 2rem auto;
}

.json-html li{
    font-weight: bold;
    font-size: 24px;
    font-family: 'Mont';
    color: #ecbdba;
    margin: 2rem auto;

}

.json-html ol{
    padding: 1rem;
    width: 600px;
    max-width: 100vw;
    margin: 2rem auto;
    list-style-position: inside;

}

.closing-para{
    width: 1200px;
    max-width: 90vw;
    margin: 2rem auto;
    font-size: 18px;
    letter-spacing: 1px;
    color: #1e1e1e;
    line-height: 25px;
}

.closing-para a{
    font-family: 'Mont';
    color: #ecbdba;

}

.faqs{
    width: 600px;
    max-width: 90vw;
    margin: 2rem auto;

}

.faq-item{
    border-bottom: 1px solid grey;
    padding: 1rem;
    font-weight: bold;
    font-size: 16px;
}

.secondary-img{
    width: 800px;
    max-width: 90vw;
    margin: 1rem auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;}