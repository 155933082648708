.act-container{
    height: 260px;
    width: 300px;
    max-width: 95vw;
    position: relative;
    margin: 1rem;
    border-radius: 8px;
}

.act-overlay{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(18,18,18,0.5);
    color: white;
    transition: 0.5s;
    border-radius: 8px;

}

.act-overlay:hover{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(18,18,18,0);
    color: #3B4252;
    cursor: pointer;
}