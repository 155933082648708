#admin-nav{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem auto;

}

.admin-nav-link{
    text-decoration: none;
    font-family: 'Mont';
    color: #313131;
}