#wedding-request-info{
    background-color: #fdf5f5;
    padding: 30px 50px;
    width: 450px;
    height: 100%;
    border-radius: 20px;
    position: relative;
    overflow: scroll;
}

#create-quotes-container{
    padding: 30px 50px;
    margin-left: 2rem;
    width: calc(100% - 450px - 2rem);
    min-width: 450px;
    height: 100%;
    border-radius: 20px;
    position: relative;
    overflow: scroll;
}

#wedding-request-info * {
    position: relative;
    z-index: 3;
}


.request-header{
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 12px 0;
    font-size: 14px;
}

.request-line-info{
    text-transform: lowercase;
    letter-spacing: 0px;
    font-weight: 700;
    color: #313131;
    font-size: 16px;
    max-width: 400px;

}


#artist-request-hearts{
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 50%;
    z-index: 2;
}

#request-msg-container{
    width: 100%;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 4px 4px 4px rgba(17, 17, 17, 0.1);
    padding: 10px 20px;
    margin: 20px 0;
}

#request-msg-text-box{
    resize: none;
    width: 100%;
    height: 100px;
    border: unset;
    outline: none;
    font-size: 12px;
}

@media only screen and (max-width: 1250px) {
    #wedding-request-info{
        width: 100%;
        padding: 10px 30px;
        height: auto;
    }

    #create-quotes-container{
        padding: 0px;
        margin-left: 0;
        width: 100%;
        min-width: unset;
        overflow: visible;
    }
}

@media only screen and (max-width: 550px) {
    .request-btn{
        width: 100%;
        margin: 10px 0
    }

    #request-msg-text-box{
      
        height: 130px;
    }
}