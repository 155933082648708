#competition-hero{
    background-color: #FCE0E0;
    min-height: 500px;
    padding: 100px 2rem;
    font-family: "Albert Sans", sans-serif;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

#comp-hero-right{
    width: 40%;
    max-width: 800px;
}

#comp-hero-left{
    color: black;
    width: 60%;
    padding: 2rem 1rem;
    max-width: 650px;
}


#comp-hero-left h1{
    font-weight: 900;
    font-size: 40px;
    margin: 0;
}

#comp-hero-left > p{
    font-weight: 600;
    font-size: 20px;
    margin: 3rem 0;
    width: 550px;
    max-width: 90%;
}

#comp-video-img{
    width: 600px;
    max-width: 100%;
}

#comp-video-container{
    position: relative;
}

#play-comp-btn{
    position: absolute;
    height: 50px;
    width: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
}

#comp-info{
    width: 1400px;
    max-width: 95vw;
    margin: 4rem auto;
}

.comp-img{
    width: 500px;
    max-width: 100%;
    margin: 2rem 0;
}

.comp-info-row{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    width: 100%;
    padding: 1rem 0;
}

.comp-spacer{
    width: 10%;
    height: 50px;
}

.comp-info-txt{
    width: 450px;
    max-width: 100%;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
}

.comp-info-txt h3{
    font-size: 26px;
    font-weight: 900;
    color: #EABAB7;
}

#comp-img-mobile{
    display: none;
}

@media only screen and (max-width: 1000px) {

    #competition-hero{
        padding: 1rem;
        justify-content: left;
        align-items: baseline;
    }
    #comp-hero-left,  #comp-hero-right{
        width: 100%;
        max-width: unset;
    }

    #comp-hero-left h1{
        font-size: 42px;
    }

    #hero-mobile{
        display: block;
        position: relative;
        left: -2rem;
        margin-top: 2rem;
        width: 100vw;
    }

    #comp-hero-desktop{
        display: none;
    }

    #hero-highlights{
        margin: 0 auto;
    }

    .comp-info-row{
        padding:  0;
        position: relative;
    }

    #comp-info-hearts{
        position: absolute;
        top: 0;
        right: 1rem;
        z-index: -1;
    }

    #comp-info{
        margin: 2rem auto;
    }
    #comp-img-mobile{
        display: block;
    }

    #comp-img-desktop{
        display: none;
    }
  }

  @media only screen and (max-width: 1250px) {
    .comp-spacer{
        display: none;
    }
    .comp-info-row{
        justify-content:  space-evenly;
    }
  }

