#wedding-dash{
    position: absolute;
    left: 256px;
    top: 0;
    background-color: #fbebec;
    min-height: 100vh;
}

#wedding-dash-left{
    display: flex;
    flex-direction: column;
    width: calc(100vw - 706px);
    padding: 2rem;
    background-color: white;
    border-radius: 0 30px 30px 0;
    height: 100vh;
    overflow: scroll;
    position: relative;
}

#dash-hearts{
    width: 25%;
    max-width: 250px;
    position: absolute;
    bottom: 2vh;
    right: 5%;
    z-index: 2;
}

.wedding-dash-img{
    width: 20%;
    min-width: 40px;
    margin-bottom: 20px;
}

#countdown-container{
    background-color: #FCE0E0;
    border-radius: 15px;
    padding: 30px;
    color: #313131;
    height: 220px;
    margin: 1rem 0 2rem 0;
    z-index: 3;
}

#countdown-container *{
    font-weight: 900;
}

#days-to-go{
    color: white;
    font-size: 100px;
    margin: 0;
    margin-right: 20px;
    height: 100px;
}

#dash-calendar-icon{
    width: 10%;
    min-width: 100px;
}

.wedding-dash-container{
    background-color: #fdf5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    border-radius: 15px;
    font-family: 'Mont', sans-serif;
    width: 31.5%;
    min-width: 150px;
    height: 12vw;
    min-height: 150px;
    text-decoration: none;  
    position: relative;
    z-index: 3;

}

.wedding-dash-container p{
    font-weight: 900;
    margin: 0;
    color: #313131;
    font-size: 20px;
}

#wedding-dash-containers{
    display: flex;
    justify-content: space-between;
}

.timing-input{
    margin: 1rem 0;
    width: 47.5%;
    height: 60px;
    border-radius: 4px;
    border: 1px solid rgb(180, 180, 180);
    padding: 0 10px;
    font-size: 16px;
    color: #1e1e1e;
}

.timing-input::placeholder{
    color: rgb(110, 110, 110);
}

#wedding-container{
    min-height: 80vh;
    width: 1400px;
    max-width: 100vw;
    padding: 2rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.wedding-sub-container{
    width: 600px;
    max-width: 90vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
}

.quote-dash-container{
    height: 100px;
    width: 60%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 1rem 0;
    border-radius: 8px;
    padding: 10px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.quote-dash-container * {
    margin: 0;
}

.quote-highlight{
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background-color: #ecbdba;
}

.quote-top-line{
    display: flex;
    justify-content: space-between;
    font-weight: 900;
}

.gross-quote{
    font-weight: 900;
}

#schedule-tab{
    display: none;
}

.countdown-info{
    display: flex;
    justify-content: space-between;
    align-items: end;
}

#countdown-txt{
    font-size: 22px;
    margin: 0;
}

#countdown-txt-mobile{
    display: none;
    margin: 0;

}

@media only screen and (max-width: 1350px) {
    #wedding-dash-left{
        width: calc(100vw - 256px);
        border-radius: 0;
    }

    #schedule-tab{
        display: block;
        background-color: #ecbdba;
        color: white;
        width: 180px;
        font-weight: 900;
        padding: 10px 10px;
        text-align: center;
        border-radius: 8px 8px 0 0;
        position: fixed;
        right: -70px;
        top: 50vh;
        z-index: 999;
        transform: rotate(-90deg);
    }

    #schedule-tab:hover{
        cursor: pointer;
    }
   
  }

  @media only screen and (max-width: 900px) {
    #dash-calendar-icon{
        width: 15%;
        min-width: 70px;
    }

    #days-to-go{
        font-size: 80px;
        margin: 0;
        margin-right: 20px;
    }

    #countdown-container{
        height: 190px;
    }

    .countdown-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  }

  @media only screen and (max-width: 750px) {
    #wedding-dash-left{
        width: 100vw;
        padding-top: 90px;
    }

    #wedding-dash{
        left: 0;
    }
  }

  @media only screen and (max-width: 550px) {
    #wedding-dash-containers{
        flex-direction: column;
    }

    .wedding-dash-container{
    
        width: 100%;
        height: 120px;
        min-height: unset;
        margin: 10px 0;
        flex-direction: row;
        align-items: center;
        justify-content:start;
    
    }

    .wedding-dash-img{
        width: 50px;
        min-width: unset;
        margin-bottom: 0;
        margin-right: 20px;
    }

    #countdown-container{
        margin-bottom: 10px;
    }

    .quote-dash-container{
        width: 100%;
    }

    #dash-hearts{
        display: none;
    }

    #countdown-txt{
        display: none;
    }
    
    #countdown-txt-mobile{
        display: block;
        font-size: 22px;
    
    }

    #dash-calendar-icon{
        width: 50px;
        min-width: unset;
    }

    #wedding-dash-left{
        padding: 90px 1rem;

    }
  }
