#dashboard-calendar{
    height: 100vh;
    width: 450px;
    position: fixed;
    right: 0;
    top: 0;
    background-color: #fbebec;
    overflow: scroll;
    padding: 2rem 0;
    z-index: 998;
    color: #313131;
    font-weight: 500;
}

#dashboard-calendar h3{
    font-size: 28px;
    font-weight: 900;
    margin: 0 0 5px 30px;
}

.schedule-item-container{
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
    border-radius: 8px;
    margin: 1rem 0;
    width: 75%;
    position: relative;
}

.schedule-item-container:hover{
    cursor: pointer
}

.timeline-container{
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeline-dot{
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: white;
}

.timeline-line{
    width: 2px;
    height: 70px;
    background-color: white;
}

.timeline-no-line{
    width: 2px;
    height: 70px;
    background-color: unset;
}

.disabled-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FCE0E0;
    opacity: 32%;
}

#add-to-schedule-btn{
    background-color: #EABAB7;
    color: white;
    height: 50px;
    border-radius: 25px;
    border: unset;
    padding: 10px 20px;
    font-weight: 900;
    font-size: 18px;
    margin: 1rem 30px;
}

#close-planner-btn{
    display: none;
}

@media only screen and (max-width: 1350px) {
    #dashboard-calendar{
        width: 0;
        transition: 0.3s ease-in-out;
        z-index: 1000;

    }

    .planner-open{
        width: 450px !important;
        max-width: 100vw !important;
        transition: 0.5s ease-in-out;
    }

    #close-planner-btn{
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        height: 30px;
        border-radius: 15px;
        padding: 5px 10px;
        background-color: unset;
        border: 2px solid #EABAB7;
        color: #EABAB7;
        font-weight: 900;
    }
}