.msg-input{
    width: 550px;
    max-width: 95vw;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    padding-left: 10px;
    margin: 0.5rem;
}

#msg-body{
    width: 550px;
    max-width: 95vw;
    min-height: 400px;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    padding: 10px;
    margin: 0.5rem;
    resize: none;
}

#message{
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.send-btn{
    background-color: #ecbdba;
    color: white;
    font-weight: bold;
    font-family: 'Mont', sans-serif;
    padding: 1rem;
    border: unset;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 1rem;
    cursor: default
}

.send-btn:disabled {
    cursor: not-allowed;
}
