*{
  box-sizing: border-box;
  font-family: 'Albert Sans', sans-serif;
}


.fdm-btn{
  background-color: #ecbdba;
  border-radius: 3px;
  min-width: 150px;
  color: white;
  font-weight: bold;
  border: unset;
  height: 40px;
  transition: 0.5s;
}

.inverted-btn{
  background-color: white;
  border-radius: 3px;
  min-width: 150px;
  color: #ecbdba;
  font-weight: bold;
  border: 2px solid #ecbdba;
  height: 40px;
  transition: 0.5s;
}

.fdm-btn:hover, .inverted-btn:hover{
  cursor: pointer;
  background-color: #e3a8a4;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

button:hover{
  cursor: pointer;
}

.hover:hover{
  cursor: pointer;
}

.vertical-spacer{
  margin: 10px 0;
}

.hover-with-background:hover{
  background-color: #f1f1f1;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
}
.header-popover{
  background-color: white;
  min-height: 100px;
  width: 200px;
  border-radius: 8px;
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 999;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  color: #313131;
}

.popover-item{
  padding: 1rem;
  margin: 0;
  transition: 0.3s;

}

#dropdown-wrapper{
  border: 1px solid #fff;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 20px;
}

#burger-container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 30px;
  width: 20px;
  margin: 0 10px;
}

.burger-line{
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.divider{
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
  margin: 5px 0;
}

input[type='date'],
input[type='time']{
  -webkit-appearance: none; /*Used to prevent mobile styling inconsistencies */

}

.primary-btn{
  background-color: #e3a8a4;
  border: unset;
  border-radius: 5px;
  color: white;
  font-family: 'Mont';
  padding: 10px;
}

.transparent-btn{
  color: #EBA7A2;
  font-weight: 700;
  font-size: 20px;
  background-color: unset;
  height: 48px;
  border-radius: 24px;
  border: 1px solid #EBA7A2;
  padding: 10px 20px;
}

.white-btn{
  color: #313131;
  font-weight: 700;
  font-size: 20px;
  background-color: unset;
  height: 48px;
  border-radius: 24px;
  border: unset;
  padding: 10px 20px;
  box-shadow: 4px 4px 4px rgba(17, 17, 17, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.solid-black-btn{
  padding: 10px 20px;
  background-color: black;
  color: #fff;
  border-radius: 25px;
  font-weight: 700;
  border: unset;
}

.black-btn{
  padding: 10px 20px;
  background-color: unset;
  border: 1px solid #313131;
  color: #313131;
  border-radius: 25px;
  font-weight: 700;
}

.pink-btn{
  background-color: #EABAB7;
  color: white;
  height: 50px;
  border-radius: 25px;
  border: unset;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 20px;
}

.disabled-btn{
  background-color: #dfdfdf;
  border: unset;
  border-radius: 5px;
  color: white;
  font-family: 'Mont';
  padding: 10px;
}

.disabled-btn:hover{
  cursor: not-allowed;
}

.green-btn{
  background-color: green;
  border: unset;
  border-radius: 5px;
  color: white;
  font-family: 'Mont';
  padding: 10px;
}

.mobile-menu-item{
  display: none;

}

/*For a custom switch component*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ecbdba;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ecbdba;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.mobile-header{
  display: none;
}

.mobile{
  display: none;
}

@font-face {
  font-family: 'Mont';
  src: local('Mont'), url(Fonts/Mont-Heavy.otf) format('opentype');
}

@font-face {
  font-family: 'MontLight';
  src: local('MontLight'), url(Fonts/Mont-light.otf) format('opentype');
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
   url("./Fonts/Satoshi-Medium.otf") format("opentype");
 }

@media only screen and (max-width: 748px) {
  .mobile{
    display: block;
  }

  .mobile-remove{
    display: none !important;
  }

  .mobile-menu-item{
    display: block;
    text-decoration: none;
    color: #313131;
  }

  .mobile-header{
    display: block;
    background-color: white;
    width: 100vw;
    height: 75px;
    z-index:1000;
    position: fixed;
    top: 0;
    box-shadow: 0px 1px 3px rgba(236, 189, 186, 0.5)
    ;

  }
 }