#chat-page{
    min-height: 75vh ;
    padding: 2rem;
    width: calc(100% - 256px);
    background-color: rgba(252,224,224,0.1);
}

#msg-box{
    height: 20vh;
    width: 98%;
    max-width: 800px;
    margin: 2rem auto;
    border: unset;
    border-radius: 8px;
    background-color: white;
    color: #1e1e1e;
    font-size: 16px;
    padding: 1rem;
    resize: none;
    border: 1px solid #f1f1f1;

}

.other-msg{
    background-color: #ecbdba;
    color: white;
    padding: 1rem;
    width: 600px;
    max-width: 80%;
    border-radius: 0 10px 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;    
    margin: 1rem;
}

.own-msg{
    background-color: #fff;
    padding: 1rem;
    width: 600px;
    max-width: 80%;
    border-radius: 10px 0 10px 10px ;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 1rem;
}

.own-msg-container{
    display: flex;
    justify-content: right;
}

.timestamp{
    color: grey;
}

#message-container{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

#send-btn{
    background-color: #ecbdba;
    color: white;
    font-weight: bold;
    font-family: 'Mont', sans-serif;
    padding: 1rem;
    border: unset;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#send-btn:hover{
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 2rem
}

.send-btn{
    background-color: #ecbdba;
    color: white;
    font-weight: bold;
    font-family: 'Mont', sans-serif;
    padding: 1rem;
    border: unset;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-right: 60px;
}

.send-btn:hover{
    cursor: pointer;
}

@media only screen and (max-width: 750px) {
    #chat-page{
        min-height: 75vh ;
        padding: 2rem;
        width: 100%;
        background-color: rgba(252,224,224,0.1);
    }
}