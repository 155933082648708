html {
    margin: 0;
      scroll-behavior: smooth;
      overflow: scroll;
    }
    
  body {
      margin: 0;
      
    }
  #home-view{
      margin-top: 50px;
  
  
  }

  #featured{
    min-height: 400px;
  }
  
  video{
      width: 100vw;
      height: 100%;
      object-fit: cover;
      z-index: 0;
      border: none;
  }
  
  #vid-container{
      position: relative;
      height: 70vh;
      width: 100vw;
      background-image: url('./vid-bg.png');
      background-position: center;
      background-size: cover;
   
     
  }
  
  #head-btn:hover,#head-btn2:hover{
    cursor: pointer;
  }

  #head-btn{
      min-width: 200px;
      width: 25%;
      height: 60px;
      max-height: 100px;
      background-color: #ecbdba;
      border: #ecbdba ;
      color: white;
      position: absolute;
      bottom: 10%;
      left: 30px;
      margin: 0;
      font-weight: bold;
      font-family: 'Mont', sans-serif;
      font-size: 0.8rem;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  #head-btn2, #head-btn3{
      min-width: 150px;
      width: auto;
      height: 60px;
      max-height: 100px;
      background-color: white;
      border: white ;
      color: #ecbdba;
      position: absolute;
      bottom: 10%;
      left: 30%;
      margin: 0;
      padding: 0 1rem;
      font-size: 0.8rem;
      font-weight: bold;
      font-family: 'Mont', sans-serif;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  #head-btn3{
    display: none;
  }
  
  #sales-pitch{
      text-align: left;
      position: relative;
      left: 20px;
      top: 35%;
      line-height: 1.8rem;
      font-size: 1.2rem;
      font-weight: 400;
      word-spacing: 0.2rem;
      width: 50vw;
  }
  
  #gradient {
      background: rgba(18,18,18, 0);
      background: linear-gradient(180deg, rgba(18,18,18,0) 50%, rgba(18,18,18,1) 100%);
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      color: white;
      padding: 2rem 1rem;
      text-align: center;
    }
  
   #featured{
      color: #7F7F7F;
      height: auto;
      width: 100vw;
      padding: 2rem 1rem;
      display: block;
  }
  
  #head-logo{
      max-height: 150px;
      position: absolute;
      top: 10%;
      left: 30px;
      display: none;
  }
  #head-logo-long{
    max-height: 70px;
    position: absolute;
    top: 25%;
    left: 30px;
    max-width: 90vw;
}
  
  .slick-slider{
    width: 92vw;
    margin: 0.5rem auto;
  }
  
  
  .profile-slider{
    width: 100vw;
    margin: 1rem 0;
  }
  
  .profile-slider h1{
    text-align: left;
    display: block;
    width: 100%;
    padding: 1rem 2rem;
  }
  
  
  
  @media only screen and (max-width: 850px) {
    #head-btn{
      min-width: 180px;
      width: 35vw;
      position: absolute;
      bottom: 10%;
      left: 5%;
     
  }
  #head-btn2{
      min-width: 120px;
      position: absolute;
      bottom: 10%;
      left: 45%;
     
  }
  #sales-pitch{
    width: 80vw;
  }
  }
  
  @media only screen and (max-width: 550px) {
    .profile-slider h1{
      font-size: 1.2rem;
      text-align: center;
    }
  
    #sales-pitch{
      display: none;
  }
  #head-btn{
    min-width: 120px;
    width: 40vw;
    left: 5%;
   
  }
  #head-btn3{
    display: none;
  }

  #head-btn2{
    min-width: 120px;
    width: 40vw;
    position: absolute;
    bottom: 10%;
    left: 55vw;
    display: flex;
  }
  
  #head-logo{
    width: 40vw;
    position: absolute;
    left: 30vw;
    top: 100px;
    display: block;
  }

  #head-logo-long{
    display: none;
  }
  
  .slick-slider{
    width: 80vw;
  }
  
  }
  
  @media only screen and (min-width: 1400px) {
    
    #sales-pitch{
      font-size: 1.2vw;
      line-height: 2rem;
    }
    
    
   
  }
  