.artist-quote-minimised{
    width: 100%;
    height: 0px;
    background-color: white;
    transition: 0.3s ease-in-out;
    padding: 0 20px;
}

.artist-quote-expanded{
    width: 100%;
    height: 200px;
    background-color: white;
    transition: 0.3s ease-in-out;
    padding: 20px;
}

.artist-quote-expanded-with-video{
    width: 100%;
    height: 375px;
    background-color: white;
    transition: 0.3s ease-in-out;
    padding: 20px;
}


.quote-option-container{
    width: 100%;
    background-color: #fdf5f5;
    border-radius: 10px;
    box-shadow: 4px 4px 4px rgba(17, 17, 17, 0.1);
    margin: 20px 0;
    overflow: hidden;
}

.quote-option-container-highlighted{
    width: 100%;
    background-color: #fdf5f5;
    border-radius: 10px;
    box-shadow: 4px 4px 4px rgba(17, 17, 17, 0.1);
    margin: 20px 0;
    overflow: hidden;
    border: 2px solid #EBA7A2;

}

.quote-expanded-header{
    color: #EBA7A2;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 10px
}

.quote-expanded-txt{
    color: grey;
    font-weight: 700;
}

.quote-highlight-circle{
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 750px) {
    .quote-option-container{
      
        margin: 20px 10px;
        width: calc(100% - 20px);
    }
  }