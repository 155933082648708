#request-list-page{
    min-height: 90vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#request-list-table{
    width: 1300px;
    max-width: calc(100vw - 2rem);
    margin: 0 auto;
    border-collapse:collapse;
    overflow: scroll;
}


#request-list-table tr:hover{
    cursor: pointer;
}

#request-list-page > *{
    color: #434C5E;
}

#request-list-table th{
    text-align: left;
    padding: 10px 0;
    font-family: 'Mont';
}

#thead-tr{
    border-bottom: 1px solid #434C5E;
}

#request-list-table td{
    padding: 1rem;
}

#request-list-table tr:nth-child(even) {
    background: rgba(252, 224, 224, 0.32);
    ;
}

.archived-checkbox-label {
    position: relative;
    cursor: pointer;
}

.archived-checkbox-label .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.archived-checkbox-label:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.archived-entry {
    color: #AAA;
}

.highlighted-row {
    background-color: #f9f9f9;
    font-weight: bold;
    border-left: 3px solid #ECBDBA;
}

#request-list-container{
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: visible;
}

.artist-request-container{
    width: calc(25% - 40px);
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 20px 20px 30px;
    color: #313131;
    margin: 20px;
    overflow: hidden;
    flex-shrink: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;

}

.green-highlight{
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background-color: #C1E1C1;
}

.red-highlight{
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background-color: #ff746c;
}

#request-filter-icon{
    position: absolute;
    top: 2rem;
    right: 2rem;
    overflow: visible;
}

#filter-option-popover{
    position: absolute;
    right: 0px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    z-index: 999;
    border-radius: 12px;
    min-width: 170px;
}

.filter-list-option{
    padding: 5px 10px;
    margin: 5px ;
    transition: 0.3s ease-in-out;
    border-radius: 5px;

}

.filter-list-option:hover{
    background-color: #f1f1f1;
    border-radius: 5px;
}

@media only screen and (max-width: 1600px) {
    .artist-request-container{
        width: calc(33% - 40px);
    }
    
  }

@media only screen and (max-width: 1250px) {
    .artist-request-container{
        width: calc(50% - 40px);
    }
    
}

@media only screen and (max-width: 850px) {
    .artist-request-container{
        width: calc(100% - 16px);
        margin: 20px 8px;
    }


    #request-filter-icon{
        position: absolute;
        top: 100px;
        right: 2rem;
    }
    
}