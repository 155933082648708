.admin-act-img{
    width: 70px;
    height: 50px;
    object-fit: cover;
    object-position: center;
}

#act-list-table th{
    text-align: left;
}

#act-table-row:hover{
    cursor: pointer;
}