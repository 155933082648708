.container {
    display: flex;
    padding: 0 55px;
  }
  
  .item {
    background: green;
    flex: 0 0 19.7%;
    text-align: center;
    margin: 0 2px;
    transition: transform 300ms ease 100ms;
  }

 #featured-artists{
  background-color: #FCE0E0;
  padding-left: 2rem;
  padding-top: 1rem;
  overflow: hidden;
 }

 #feature-scroller{
  display: flex;
  overflow: scroll;
  padding-bottom: 3rem;
  padding-right: 2rem;
  margin-top: 1rem;
 }