.blog-container{
    width: 400px;
    max-width: calc(100vw - 2rem);
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}
.blog-container:hover{
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.blog-container-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 30px

}
.blog-container-title{
    color: white;
    position: absolute;
    font-family: 'Mont';
    font-size: 28px;
    text-align: center;
    max-width: 90%;
}