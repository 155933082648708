.app-download-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 1400px;
    margin: 0 auto;
    max-width: 100vw;
    padding: 4rem 2rem;
}

.app-preview{
    width: 25%;
}

.app-images{
    width: 45%;
}

.app-download-info{
    width: 40%;
}

#app-header-txt{
    color: #1e1e1e;
    font-weight: 900;
    font-size: 64px;
    margin-top: 0;
}

.app-icon{
    width: 30%;
    margin: 2rem 0;
}

#why-download{
    color: #1e1e1e;
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#why-download h2{
    font-size: 40px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 70px;
}

#app-features{
    display: grid;
    grid-template-columns: repeat(2, 450px); 
    gap: 50px; 
}

.app-feature-container{
    display: flex;
    text-align: left;
    font-weight: 600;
}

.app-feature-container h3{
    font-weight: 900;
    margin-top: 0;
}

.app-feature-icon{
    height: 100px;
    width: auto;
    object-fit: contain;
    margin-right: 30px;
}

@media only screen and (max-width: 1100px) {
    .app-download-info{
        width: 60%;
    }

    #app-features{
        grid-template-columns: repeat(2, 350px); 
    }
}

@media only screen and (max-width: 850px) {
    .app-download-container{
        flex-direction: column;
    }
    .app-download-info{
        width: 100%;
        text-align: center;
    }

    .app-preview{
        width: 50%;
    }

    .app-images{
        width: 90%;
        margin-top: 1rem;
    }

    .app-icon{
        width: 45%;
    }
    #app-header-txt{
        font-size: 56px;
    }
    #app-features{
        grid-template-columns: repeat(1, 100%); 
        gap: 2rem;
    }
}
@media only screen and (max-width: 475px) {
    .app-preview{
        width: 95%;
        margin: 20px 0;
    }
    #app-header-txt{
        font-size: 42px;
    }
    .app-download-container{
        padding: 2rem 1rem;
    }
    .app-feature-icon{
        height: 70px;
        width: auto;
    }
    #why-download{
        padding: 2rem 1.5rem;
    }
    #why-download h2{
        margin-bottom: 2rem;
    }
}
