#page-not-found{
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    color: #313131;
    font-size: 28px;
    min-height: 80vh;
    padding: 2rem;
    text-align: center;
}

#broken-heart{
    width: 300px;
    max-width: 70vw;
}

#not-found-btn{
    background-color: unset;
    border: 2px solid #313131;
    color: #313131;
    padding: 10px;
    border-radius: 8px;
    font-weight: 900;
    font-size: 18px;
    margin: 10px auto;
}