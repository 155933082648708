.success-page{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    min-height: 80vh;
    color: #313131;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    overflow: hidden;
}

.success-page h1{
    font-weight: 900;
    font-size: 56px;
    color: #ecbdba;
}

#disco-ball{
    width: 300px;
    max-width: 90vw;
    transform: rotate(7deg);
    margin: 1rem 0;
}

@media only screen and (max-width: 458px) {
    .success-page h1{
        font-size: 36px;
    }
}