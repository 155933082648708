#find-nav{
    width: 100vw;
    height: 70px;
    background-color: #a3837f;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    align-items: center;
    position: absolute;
    top: 0;
}

#find-page{
   min-height:80vh;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   align-items: center;
   padding: 50px 2rem;

}

#find-logo{
    height: 50px
}

#request-btn{
    background-color: #ecbdba;
    color: white;
    border: 1px solid #ecbdba;
    border-radius: 3px;
    height: 40px;
    width: 150px;
    font-weight: bold;
    font-size: 1rem;
}

#request-btn:hover{
    cursor: pointer;
}

#request-date{
    height: 55px;
    width: 100%;
    background-color: unset;
    padding: 10px;
    border: 1px solid rgb(180,180,180);
    border-radius: 3px;
}

.find-nav-link{
    margin: 0 1rem;
    text-decoration: none;
    font-weight: bold;
}

#post-header{
    font-family: 'Albert Sans', sans-serif;
    font-weight: 900;
    color: #313131;
    font-size: 54px;
    margin: 0;
}

.timing-input{
    margin: 1rem 0;
    width: 47.5%;
    height: 60px;
    border-radius: 4px;
    border: 1px solid rgb(180, 180, 180);
    padding: 0 10px;
    font-size: 16px;
    color: #1e1e1e;
}

.timing-input::placeholder{
    color: rgb(110, 110, 110);
}

.enquire-container{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

#comp-hearts{
    width: 30%;
    max-width: 200px;
}

#comp-hearts-mobile{
    display: none;
}

@media only screen and (max-width: 1250px) {
    
    #find-page{
        justify-content: baseline;
        align-items: baseline;
        padding: 2rem;
    
    }
}

@media only screen and (max-width: 458px) {
    
    #find-page{
        justify-content: baseline;
        align-items: baseline;
        padding: 1rem;
    
    }

    #post-header{
        text-align: left;
        font-size: 24px;
    }

    #comp-hearts{
        display: none;
    }

    #comp-hearts-mobile{
        display: block;
        width: 80px;
    }

    .enquire-container{

        margin-top: 0;
    }
}