#empty-wallet-container{
    font-family: 'Satoshi';
    font-size: 18px;
    padding-top: 30px;
    color: grey;
}

#empty-wallet{
    width: 300px;
    max-width: 50vw;
}

#current-balance, #available-balance{
    border: 2px solid #f1f1f1;
    padding: 1rem 2rem;
    width: 40%;
    font-weight: bold;
    color: #313131;
}

#available-balance{
    border-left: unset;
    border-radius: 0 12px 12px 0;
}

#current-balance{
    border-radius: 12px 0 0 12px;
}

#current-balance h2, #available-balance h2{
    color: #ecbdba;
    font-family: 'Mont';
    font-size: 28px;
    margin: 0;
}

.wallet-tab-container{
    padding: 1rem 0;
    min-width: 100px;
    text-align: center;
    margin-right: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;

}

.wallet-tab-underline{
    height: 3px;
    width: 100%;
    background-color: #ecbdba;
}

.wallet-table th{
    text-align: left;
    padding: 10px 0;
    color: #313131;
    max-width: 200px;
}

.wallet-table td{
    text-align: left;
    padding: 10px 30px 10px 0;
    color: #313131;
}
.invoice-btn{
    background-color: unset;
    border: unset;
    font-family: 'Mont', sans-serif;
    color: #ecbdba;
    text-decoration: underline;
}

@media only screen and (max-width: 750px) {
    #current-balance, #available-balance{
        border: 2px solid #f1f1f1;
        padding: 10px 20px;
        width: 50%;
        font-weight: bold;
        color: #313131;
    }

    #current-balance h2, #available-balance h2{
        font-size: 24px;
    }
}
