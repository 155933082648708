.auto-booking {
  background-color: #7eb37a; /* Green */
  border-color: #7eb37a;
  font-weight: bold;
}

.manual-booking {
  background-color: #A7C7E7; /* Blue */
  border-color: #A7C7E7;
  font-weight: bold;
}

.legend-color{
  height: 14px;
  width: 14px;
  border-radius: 7px;
  margin-right: 10px;
}

.legend{
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
