#our-story-view{
    margin-top: 50px;
    color: white;
    padding: 2rem;
    background-color: white;
    text-align: center;

}
#our-story-view h1{
    color: #a3837f;
}

#our-story-view p{
    color: #a3837f;
}
.polaroid{
    width: 350px;
    max-width: 90vw;
}

.wedding-polaroids{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.polaroid-description{
    width: 450px;
    max-width: 90vw;
    text-align: left;
}


