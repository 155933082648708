#inbox-side-panel{
    background-color: #fdecec;
    position: fixed;
    left: 256px;
    top: 0;
    overflow: scroll;
    height: 100vh;
    width: 350px;
    border-radius: 0 30px 30px 0;
    padding: 30px 0;
    transition: 0.3s ease-in-out;
}

#inbox-side-panel h1{
    font-weight: 900;
    margin-bottom: 20px;
    margin-left: 2rem;

}

.inbox-preview{
    padding: 15px 20px;
    height: 100px;
}

.inbox-preview-active{
    padding: 15px 20px;
    background-color: white;
    height: 100px;

}

.inbox-preview-msg{
    display: -webkit-box;
    -webkit-line-clamp: 2;   /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;       /* Adjust based on your font size */
    max-height: 3em;     
    margin: 0;
    margin-top: 7px;
    font-size: 14px;
    padding-right: 10px;
    width: 80% ;
}

.inbox-list-name{
    font-weight: 900;
    margin: 0 ;
}



.inbox-envelope{
    width: 20px;
    object-position: center;
    object-fit: contain;
}

.client-chat{
    width: calc(100vw - 656px);
    height: 100vh;
    position: absolute;
    top: 0;
    left: 606px;
    overflow: scroll;
    padding: 50px 30px 200px 30px;
}

#client-message-container{
    position: fixed;
    bottom: 2rem;
    right: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(100vw - 706px);
    z-index: 3;
}

#client-msg-box{
    width: 90%;
    margin: 1rem auto;
    border-radius: 20px;
    height: 59px;
    background-color: #fffafa;
    border: unset;
    box-shadow: 5px 3px 3px 0px rgba(217,217,217,1);
    -webkit-box-shadow: 5px 3px 3px 0px rgba(217,217,217,1);
    -moz-box-shadow: 5px 3px 3px 0px rgba(217,217,217,1);
    position: relative;
    z-index: 3;
}

#client-chat-textarea{
    resize: none;
    padding: 20px;
    width: 85%;
    height: 100%;
    position: relative;
    background-color: unset;
    border: unset;
    left: 60px;
    font-size: 16px;
}

#client-chat-textarea:focus{
    outline: none;
}

#client-send-icon{
    position: absolute;
    top: 17px;
    right: 17px;
    height: 25px;
    width: 25px;
}

#chat-hearts{
    position: fixed;
    bottom: 15vh;
    right: 10vw;
    z-index: 1;
    width: 30%;
}

.client-msg-user{
    background-color: #fffafa;
    padding: 1.5rem;
    width: 600px;
    max-width: 80%;
    border-radius: 20px 20px 0px 20px ;
    margin: 1rem;
    color: #313131;
    position: relative;
    z-index: 3;
    box-shadow: 5px 3px 3px 0px rgba(217,217,217,1);
    -webkit-box-shadow: 5px 3px 3px 0px rgba(217,217,217,1);
    -moz-box-shadow: 5px 3px 3px 0px rgba(217,217,217,1);
}

.client-msg-other{
    background-color: #fdecec;
    color: #313131;
    padding: 1.5rem;
    width: 600px;
    max-width: 80%;
    border-radius: 20px 20px 20px 0px ;
    margin: 1rem 1rem 1rem 2rem;
    position: relative;
    z-index: 3;
    box-shadow: 5px 3px 3px 0px rgba(217,217,217,1);
    -webkit-box-shadow: 5px 3px 3px 0px rgba(217,217,217,1);
    -moz-box-shadow: 5px 3px 3px 0px rgba(217,217,217,1);

}

.client-msg-container{
    display: flex;
    justify-content: right;
}

#inbox-tab{
    display: none;
}

#client-emoji-selector{
    position: absolute;
    left: 15px;
    top: 15px;
    height: 30px;
    width: 30px;
}

#client-chat-fab{
    position: fixed;
    top: 1rem;
    right: 2rem;
    z-index: 999;
    text-align: right;
}

.client-timestamp{
    color: #a9a9a9;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    right: 15px;
}


@media only screen and (max-width: 1350px) {
    #inbox-side-panel{
        width: 0;
        transition: 0.3s ease-in-out;
        z-index: 1000;

    }

    .client-chat{
        width: calc(100vw - 256px);
        height: 100vh;
        position: absolute;
        top: 0;
        left: 256px;
        overflow: scroll;
        padding: 50px 30px 200px 30px;
    }

    #inbox-tab{
        display: flex;
        justify-content: center;
        background-color: #ecbdba;
        color: white;
        width: 150px;
        font-weight: 900;
        padding: 10px 10px;
        text-align: center;
        border-radius: 0 0 8px 8px;
        position: fixed;
        left: 200px;
        top: 50vh;
        z-index: 997;
        transform: rotate(-90deg);
    }

    .inbox-open{
        width: 450px !important;
        max-width: 100vw !important;
        transition: 0.5s ease-in-out;
    }

    #client-message-container{
        width: calc(100vw - 356px);
    }
}

@media only screen and (max-width: 750px) {
    .client-chat{
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        overflow: scroll;
        padding: 100px 0px 200px 0;
    }

    #inbox-tab{
   
        left: -55px;
        
    }

    #inbox-side-panel{
    
        left: 0;
        border-radius: 0 ;

    }

    #client-message-container{
        width: calc(100vw - 20px);
        right: 10px;
    }

    #client-chat-fab{
        top: 70px;
        right: 1rem;
    }
    
}
