#navbar{
    height: 50px;
    width: 100vw;
    background-color: #ecbdba;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
#inverted-navbar{
    height: 50px;
    width: 100vw;
    background-color: #fff;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

#inverted-navbar nav * {
    color: #ecbdba !important;

}

#global-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@font-face {
    font-family: 'Mont';
    src: local('Mont'), url(../../Fonts/Mont-Heavy.otf) format('opentype');
  }
    


  #global-nav a {
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-family: 'Mont', sans-serif;
    font-size: 0.8rem;
    margin: 0 1rem;
    cursor: pointer;
    transition: 0.5s;
    letter-spacing: 2px;
    display: inline-block;
}

.find-nav-link{
    font-family: 'Mont', sans-serif;

}

#post-wedding-nav {
    display: inline-flex !important;
    justify-content:center; 
    align-items: center;
}

@media only screen and (max-width: 750px) {
    #global-nav{
        width: 90%;
        
    }
    
  }

  @media only screen and (max-width: 600px) {
    .main-nav,  #post-wedding-nav {
        display: none !important
    }

    #inverted-navbar{
        padding:5px;
    }
    
  }