.toggle-switch {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 60px;
    height: 30px;
    padding: 3px;
    position: relative;
    transition: background-color 0.3s ease;
}

.toggle-knob {
    background-color: #fff;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    position: absolute;
    top: 3px;
    transition: left 0.3s ease;
}
