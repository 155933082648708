#login-page{
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    background-color: #1e1e1e;
    padding-top: 2rem;
}

#credentials-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    background-color: white;
    max-height: 90vh;
    max-width: 95vw;
}

#login-btn{
    background-color: #ecbdba;
    border: 1px solid #ecbdba;
    color: #f5f5f5;
    border-radius: 5px;
    margin: 10px;
    padding: 5px 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-weight: bold;
    font-size: 1rem;
    height: 40px;
    width: 150px;
    margin-bottom: 1rem;
}

#login-btn:hover{
    cursor: pointer;
}

.login-input{
    margin: 1rem 0;
    height: 50px;
    width: 250px;
    padding: 1rem;
    font-size: 16px;
}

#login-logo{
    width: 200px;
    margin-bottom: 2rem;
}

#forgotten{
    text-decoration: underline;
    color: #ecbdba;
    font-weight: bold;
}
#forgotten:hover{
    cursor: pointer;
}