#personal-tab{
    display: flex;
    flex-direction: column;
    justify-content: top;
    height: calc(100vh - 220px)
}

#profile-artist-img{
    width: 150px;
    height: 125px;
    margin: 2rem auto;
    object-fit: cover;
    object-position: center;
}

#personal-info{
    display: flex;
    flex-direction: column;
    width: 700px;
    max-width: 90vw;
    margin: 0 auto;
    align-items: center;
}

.wedding-tabs{
    display: flex;
}

.wedding-tab{
    border-bottom: 1px solid #ecbdba;
    padding: 10px 20px;
    background-color: #fffafa;
    font-weight: 700;
    border-radius: 8px 8px 0 0 ;

}

.wedding-tab p, .wedding-tab-active p{
    margin: 0;
}

.wedding-tab:hover, .wedding-tab-active:hover{
    cursor: pointer;
}

.wedding-tab-active{
    border-bottom: unset;
    border-radius: 8px 8px 0 0 ;
    border-top: 1px solid #ecbdba;
    border-left: 1px solid #ecbdba;
    border-right: 1px solid #ecbdba;
    padding: 10px 20px;
    font-weight: 700;
}