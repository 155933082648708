#hero{
    background-color: white;
    min-height: 500px;
    padding: 100px 2rem;
    font-family: "Albert Sans", sans-serif;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

}

#hero-left{
    color: #313131;
    width: 50%;
    padding: 2rem 1rem;
    max-width: 600px;
}

#hero-right{
    width: 50%;
    max-width: 800px;
}

#hero-left h1{
    font-weight: 900;
    font-size: 58px;
    margin: 0;
}

#hero-left > p{
    font-weight: 600;
    font-size: 20px;
    margin: 3rem 0;
}

.hero-btn{
    height: 44px;
    width: 30%;
    min-width: 130px;
    border-color: #ecbdba;
    background-color: #ecbdba;
    color: #fff;
    border: 1px solid #ecbdba;
    border-radius: 22px;
    font-weight: 600;
    font-size: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

#inverted-hero-btn{
    background-color: unset;
    color: #ecbdba;
    margin-left: 20px;
}

#hero-highlights{
    display: flex;
    width: 100%;
    margin-top: 150px;
}

.hero-highlight{
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 8%;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
}

.hero-highlight p{
    font-size: 16px;
}

.hero-icon{
    width: 45px;
    height: 45px;
    object-fit: contain;
    object-position: center;
}

.divider-vertical{
    width: 1px;
    height: 110px;
    background-color: #313131;
}

#hero-mobile{
    display: none;
}

@media only screen and (max-width: 1000px) {

    #hero{
        padding: 1rem;
        justify-content: left;
        align-items: baseline;
    }
    #hero-left,  #hero-right{
        width: 100%;
    
    }

    #hero-left h1{
        font-size: 42px;
    }
    #hero-highlights{
        margin-top: 3rem;
    }

    #hero-mobile{
        display: block;
        position: relative;
        left: -2rem;
        margin-top: 2rem;
        width: 100vw;
    }

    #hero-desktop{
        display: none;
    }

    #hero-highlights{
        margin: 0 auto;
    }
  }