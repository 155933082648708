#how-it-works{
    width: 100vw;
    max-width: 1500px;
    margin: 0 auto;
    padding: 4rem;
    position: relative;
}

#how-it-works-header{
    font-weight: 900;
    color: #313131;
    font-size: 36px;
}

#decoration{
    position: absolute;
    width: 50%;
    right: 2rem;
    top: 2rem;
}

#rectangle{
    position: relative;
    left: -4rem;
    object-position: center;
    object-fit: cover;
    max-width: 100vw;
}

#dj-rectangle{
    position: relative;
    left: -4rem;
    object-position: center;
    object-fit: cover;
    width: 350px;
    max-width: 100vw;
    border-radius: 0 25px 25px 0;
}

.how-it-works-step{
    width: 50%;
    padding: 1rem;
}

.how-it-works-step > h2{
    color: #ecbdba;
    font-weight: 900;
    font-size: 32px;
}

.how-it-works-step > h3{
    color: #313131;
    font-weight: 900;
    font-size: 24px;
}

#steps{
    display: flex;
}

#how-it-works-mobile{
    display: none;
    padding: 2rem;
    overflow: hidden;

}

.mobile-step{
    display: flex;
}

.mobile-step-img{
    width: 20%;
    object-fit: contain;
}

#rectangle-mobile{
    width: 100vw;
    height: 400px;
    object-fit: cover;
    margin-top: 50px;
}

@media only screen and (min-width: 1500px) {
    #rectangle{
        border-radius: 25px;
    }
    #dj-rectangle{
        border-radius: 25px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .how-it-works-step{
        width: 100%;
    }
    #rectangle{
        border-radius: 0 25px 25px 0;
    }
   
  }

  @media only screen and (max-width: 1000px) {
    #how-it-works-mobile{
        display: block;
    }
    #how-it-works{
        display: none;
    }

    #steps{
        flex-wrap: wrap;

    }
    #rectangle{
        width: 100vw;
        height: 400px;
        object-fit: cover;
        left: -2rem;
    }

    #how-it-works{
        width: 100vw;
        max-width: 1500px;
        margin: 0 auto;
        padding: 2rem;
        position: relative;
    }
    
  }

  @media only screen and (max-width: 550px) {
    .mobile-step-img{
        width: 30%;
    }
  }