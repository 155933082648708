.public-schedule-item-container{
    background-color: white;
    display: flex;
    align-items: center;
    padding: 20px 15px;
    border-radius: 8px;
    margin: 1rem 0;
    width: 75%;
    position: relative;
    flex: 1;
    margin-left: 20px;
}

.public-timeline-container{
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.public-timeline-dot{
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: white;
}

.public-timeline-line{
    width: 2px;
    height: 70px;
    background-color: white;
}