.payment-option-container-highlighted {
    width: 47%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 2px solid #313131;
    border-radius: 8px;
}

.payment-option-container-disabled {
    width: 47%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    background-color: #f1f1f1;
}

.payment-option-container-disabled:hover {
    cursor:not-allowed ;
}

.payment-option-container-disabled > * {
    color: grey !important;
}

.payment-option-container {
    width: 47%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
}

.payment-option-container-highlighted:hover, .payment-option-container:hover {
    cursor: pointer;
}

.payment-options {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    flex-wrap: wrap;
}

.payment-choice-cirle {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
}

.smallParagraph {
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 4px;
    text-align: center;
}

.back-btn{
    background-color: unset;
    color: #EBA7A2;
    border: 1px solid #EBA7A2;
    font-weight: 700;
    border-radius: 5px;
    padding: 10px 20px;
}

.card-element-container {
    padding-top: 10px;
    padding-bottom: 20px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.card-element {
    margin: 1rem 0;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    margin-right: 10px;
}

.card-element input {
    font-size: 18px;
}

.stripe-attribution img {
    width: 150px;
    margin-top: 10px;
}

.card-element.full-width {
    width: 100%;
}

.card-element.narrow-width {
    width: 33%;
}


@media (max-width: 550px) {
    .payment-option-container, .payment-option-container-highlighted, .payment-option-container-disabled {
        width: 100%;
        margin: 10px 0;
    }
}